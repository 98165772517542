<template>
  <div class="video-section">
    <div class="video-list">
      <div
        v-for="video in displayedVideos"
        :key="video.title"
        class="video-item"
        @click="openLink(video.URL)"
      >
        <img
          :src="getThumbnailURL(video.URL)"
          :alt="video.title"
          class="video-thumbnail"
          loading="lazy"
        />
        <div class="video-info">
          <h3 class="video-title">{{ truncate(video.title, 80) }}</h3>
        </div>
      </div>
    </div>
    <button
      v-if="hasMoreVideos"
      @click="showMoreVideos"
      class="secondary-button"
    >
      Show More
    </button>
  </div>
</template>

<script>
import { state } from "../state";
import axios from "axios";

export default {
  data() {
    return {
      jsonVideos: [],
      videosToShow: 8,
    };
  },
  setup() {
    return { state };
  },
  watch: {
    "state.selectedCar": {
      handler: "searchVideos",
      immediate: true,
    },
  },
  computed: {
    filteredVideos() {
      if (!state.selectedCar) {
        return this.jsonVideos.slice(0, 10);
      }

      const keywords = state.selectedCar.toLowerCase().split(" ");

      const processedVideos = this.jsonVideos
        .map((video) => {
          if (typeof video.title !== "string") return null;

          const title = video.title.toLowerCase();
          const matchCount = keywords.filter((keyword) =>
            title.includes(keyword)
          ).length;

          return matchCount > 0 ? { ...video, matchCount } : null;
        })
        .filter((video) => video !== null)
        .sort((a, b) => b.matchCount - a.matchCount);

      const topTen = processedVideos.slice(0, 10);
      const additionalMatches = processedVideos
        .slice(10)
        .filter((video) => video.matchCount >= 2);

      return topTen.concat(additionalMatches);
    },
    displayedVideos() {
      return this.filteredVideos.slice(0, this.videosToShow);
    },
    hasMoreVideos() {
      return this.filteredVideos.length > this.videosToShow;
    },
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    truncate(value, length) {
      return value.length > length ? value.substring(0, length) + "..." : value;
    },
    getThumbnailURL(videoURL) {
      const videoId = this.extractVideoId(videoURL);
      return videoId
        ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
        : "../assets/google.jpg"; // Add your fallback thumbnail path
    },
    extractVideoId(url) {
      const regex =
        // eslint-disable-next-line no-useless-escape
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=|.+\/)?([^"&?\/\s]{11})/;
      const match = url.match(regex);
      return match ? match[1] : "";
    },
    showMoreVideos() {
      this.videosToShow += 8;
    },
    async searchVideos() {
      try {
        const response = await axios.get(
          "https://bestcarfixes.com/api/videos",
          {
            params: {
              query: state.selectedCar,
            },
          }
        );
        this.jsonVideos = response.data.videos;
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    },
  },
  mounted() {
    this.searchVideos(); // Fetch videos when the component is mounted
  },
};
</script>

<style>
.video-section {
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-bottom: 20px;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.video-item {
  display: flex;
  flex-direction: column;
  width: 200px;
  border: solid 4px black;
  border-radius: 20px;
  margin: auto;
  padding: 20px;
  transition: background-color 50ms ease, translate 50ms ease;
}

.video-item:hover {
  background-color: #f2a541;
  box-shadow: 10 10px 8px rgba(0, 0, 0, 0.25);
  transform: translate(0, -10px);
}

.video-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.video-info {
  margin-top: 8px;
}

.video-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 8px 0 0;
}

.secondary-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: #0056b3;
}
</style>
